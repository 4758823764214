import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "./../views/login/AdminLogin.vue";
// AdminDashboard
import AdminDashboard from "../views/AdminDashboard";
// Jobs
import JobsAnalytics from "./../views/adminJobs/JobsAnalytics";
import JobBoards from "./../views/adminJobs/JobBoards";
import CiepalJobs from "./../views/adminJobs/CiepalJobs";
import CreateNewJobs from "./../views/adminJobs/CreateNewJobs";
import UpdateJobs from "./../views/adminJobs/UpdateJobs";
import JobPreview from "./../views/adminJobs/JobPreview";
import Candidates from "./../views/adminJobs/Candidates";
import CandidateProfile from "./../views/adminJobs/CandidateProfile";
import CompanyProfile from "./../views/adminJobs/CompanyProfile";
// Campaigns
// import SubscribersList from "./../views/adminCampaigns/SubscribersList";
// import Emails from "./../views/adminCampaigns/Emails";
// import CampaignsAnalytics from "./../views/adminCampaigns/CampaignsAnalytics";
// import CreateCampaigns from "./../views/adminCampaigns/CreateCampaigns";
// Teams
import Team from "./../views/adminCompany/Team.vue";
import CreateNewMember from "./../views/adminCompany/CreateNewMember.vue";
import UpdateMember from "././../views/adminCompany/UpdateMember.vue";
// Forms
import FormAnalytics from "./../views/adminForms/FormAnalytics";
import SubmittedList from "./../views/adminForms/SubmittedList";
import Views from "./../views/adminForms/Views";
import CreateForm from "./../views/adminForms/CreateForms";
import ViewForm from "./../views/adminForms/ViewForm";
// Resources
import CreateBlog from "./../views/resources/CreateBlog.vue";
import ViewBlogs from "./../views/resources/ViewBlogs.vue";
import UpdateBlog from "./../views/resources/UpdateBlog.vue";
import Categories from "./../views/resources/Categories.vue";
import PreviewPage from "./../views/resources/PreviewPage.vue";
// Users
import CreateUser from "./../views/users/CreateUser.vue";
import UpdateUser from "./../views/users/UpdateUser.vue";
import CreateUserManually from "./../views/users/CreateUserManually.vue";
import ManageUser from "./../views/users/ManageUser.vue";
// Access
import createRoleTemplate from "./../views/accessControl/createRoleTemplate.vue";
import accessControlMatrix from "./../views/accessControl/accessControlMatrix.vue";

// Settings
import Configurations from "./../views/adminSettings/Configurations.vue";
import AddConfiguration from "./../views/adminSettings/AddConfiguration.vue";

//MediaLibrary
import mediaLibrary from "@/views/mediaLibrary/MediaLibrary.vue";

// Not Found
import PageNotFound from "./../views/PageNotFound.vue";
import Pages from "@/views/contentTypeBuilder/Pages.vue";
import Components from "@/views/contentTypeBuilder/Components.vue";
import SinglePage from "@/views/contentTypeBuilder/SinglePage.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  // AdminDashBoard
  {
    path: "/adminDashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: { requiresAuth: true },
  },
  // Jobs
  {
    path: "/jobs",
    name: "Jobs",
    meta: { requiresAuth: true },
    children: [
      {
        path: "jobBoards",
        name: "JobBoards",
        component: JobBoards,
      },
      {
        path: "ciepalJobs",
        name: "CiepalJobs",
        component: CiepalJobs,
      },
      {
        path: "candidates/:id",
        name: "Candidates",
        component: Candidates,
      },
      {
        path: "candidateProfile/:id",
        name: "CandidateProfile",
        component: CandidateProfile,
      },
      {
        path: "createNewJobs",
        name: "CreateNewJobs",
        component: CreateNewJobs,
      },
      {
        path: "updateJobs/:id",
        name: "UpdateJobs",
        component: UpdateJobs,
      },
      {
        path: "previewJobs/:id",
        name: "JobPreview",
        component: JobPreview,
      },
      {
        path: "analytics",
        name: "JobsAnalytics",
        component: JobsAnalytics,
      },
      {
        path: "companyProfile",
        name: "CompanyProfile",
        component: CompanyProfile,
      },
    ],
  },
  // Campaigns
  // {
  //   path: "/campaigns",
  //   name: "Campaigns",
  //   // component: SubscribersList,
  //   children: [
  //     {
  //       path: "subscribersList",
  //       name: "SubscribersList",
  //       component: SubscribersList,
  //     },
  //     {
  //       path: "createCampaigns",
  //       name: "CreateCampaigns",
  //       component: CreateCampaigns,
  //     },
  //     {
  //       path: "emails",
  //       name: "Emails",
  //       component: Emails,
  //     },
  //     {
  //       path: "analytics",
  //       name: "CampaignsAnalytics",
  //       component: CampaignsAnalytics,
  //     },
  //   ],
  // },
  // Access
  {
    path: "/access",
    name: "Access",
    meta: { requiresAuth: true },
    children: [
      {
        path: "createRoleTemplate",
        name: "createRoleTemplate",
        component: createRoleTemplate,
      },
      {
        path: "accessControlMatrix",
        name: "accessControlMatrix",
        component: accessControlMatrix,
      },
    ],
  },
  // Users
  {
    path: "/users",
    name: "Users",
    meta: { requiresAuth: true },
    children: [
      {
        path: "createUser",
        name: "createUser",
        component: CreateUser,
      },
      {
        path: "updateUser/:id",
        name: "updateUser",
        component: UpdateUser,
      },
      {
        path: "createUser/createUserManually",
        name: "createUserManually",
        component: CreateUserManually,
      },
      {
        path: "manageUser",
        name: "manageUser",
        component: ManageUser,
      },
    ],
  },
  {
    path: "/content-type-builder",
    name: "Content Type Builder",
    meta: { requiresAuth: true },
    children: [
      {
        path: "pages",
        name: "Pages",
        component: Pages,
      },
      {
        path: "components",
        name: "Components",
        component: Components,
      },
      {
        path: "pages/:pageName",
        name: "SinglePage",
        component: SinglePage,
      }
    ],
  },
  // Forms
  {
    path: "/forms",
    name: "Forms",
    meta: { requiresAuth: true },
    children: [
      {
        path: "createForm",
        name: "CreateForm",
        component: CreateForm,
        props: true,
      },
      {
        path: "submittedList",
        name: "SubmittedList",
        component: SubmittedList,
      },
      {
        path: "views",
        name: "Views",
        component: Views,
      },
      {
        path: "viewform",
        name: "ViewForm",
        component: ViewForm,
      },
      {
        path: "analytics",
        name: "FormAnalytics",
        component: FormAnalytics,
      },
    ],
  },
  {
    path: "/media-library",
    name: "Media Library",
    meta: { requiresAuth: true },
    children: [
      {
        path: "lib",
        name: "Gallery",
        component: mediaLibrary,
      },
      {
        path: "lib/:id",
        name: "Subfolder",
        component: mediaLibrary,
      },
    ],
  },
  // Resources
  {
    path: "/resources",
    name: "Resources",
    meta: { requiresAuth: true },
    children: [
      {
        path: "createBlog",
        name: "CreateBlog",
        component: CreateBlog,
      },
      {
        path: "viewBlogs",
        name: "ViewBlogs",
        component: ViewBlogs,
      },
      {
        path: "viewBlogs/:blogId/update",
        name: "UpdateBlog",
        component: UpdateBlog,
      },
      {
        path: "viewBlogs/:blogId/preview",
        name: "PreviewPage",
        component: PreviewPage,
      },
      {
        path: "categories",
        name: "Categories",
        component: Categories,
      },
      {
        path: "categories/:parentCategory?/:category?/viewBlogs",
        name: "viewBlogsByCategory",
        component: ViewBlogs,
      },
      // {
      //   path: "categories/:parentCategory/:category/viewsBlogs",
      //   name: "viewBlogsByCategory",
      //   component: ViewBlogs,
      // }
    ],
  },
  // Company
  {
    path: "/company",
    name: "Company",
    // component: Team,
    meta: { requiresAuth: true },
    children: [
      {
        path: "team",
        name: "Team",
        component: Team,
      },
      {
        path: "createNewMember",
        name: "CreateNewMember",
        component: CreateNewMember,
      },
      {
        path: "updateMember/:id",
        name: "UpdateMember",
        component: UpdateMember,
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    // component: Team,
    meta: { requiresAuth: true },
    children: [
      {
        path: "configurations",
        name: "Configurations",
        component: Configurations,
      },
      {
        path: "configurations/:name",
        name: "AddConfiguration",
        component: AddConfiguration,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    meta: { requiresAuth: true },
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard to check authentication before accessing protected routes
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("token");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !isAuthenticated) {
    // Redirect to login if not authenticated and trying to access protected route
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
