<script setup>
import { ref, defineExpose, watch } from "vue";

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
    default: "Type",
  },
  name: {
    type: String,
    required: true,
    default: "type",
  },
  value: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
    default: [],
  },
});

// Reactive value based on prop
const value = ref(props.value);
const selectedOption = ref(null);

// Method to update the reactive value
const updateValue = (event) => {
  value.value = event.target.value;
  selectedOption.value = props.options.find((opt) => opt.title === value.value);
};

// Expose getData method
defineExpose({
  getData: () => {
    return { value: value.value };
  },
});

// watch for the value changes to update the selected option
watch(value, (newValue) => {
  selectedOption.value = props.options.find((opt) => opt.title === newValue);
});
</script>

<template>
  <div class="formComponentBody">
    <div class="formComponent_Title">{{ props.title }}</div>
    <div class="formRadioButtonGroup">
      <label
        v-for="option in props.options"
        :key="option.title"
        :class="{ formRadioButtonBody: true, active: value === option.title }"
      >
        <input
          type="radio"
          :id="props.id"
          :name="props.name"
          :value="option.title"
          :checked="value === option.title"
          class="radio-button-component"
          @input="updateValue"
        />
        <div class="textContainer">
          <p class="headingText">{{ option.title }}</p>
          <p class="descriptionText">{{ option.description }}</p>
        </div>
      </label>
    </div>

    <!-- conditional components rendering -->
    <div v-if="selectedOption && selectedOption.conditionalComponents">
      <div
        v-for="component in selectedOption.conditionalComponents"
        :key="component.id"
      >
        <component :is="component.component" v-bind="component" />
      </div>
    </div>
  </div>
</template>
