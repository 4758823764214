<script setup>
import { useRouter,RouterLink } from "vue-router";
import FolderIcon from "../../../public/folder.svg";
import { computed, defineProps } from "vue";

const router = useRouter();

const props = defineProps({
  editPermission : Boolean,
  deletePermission: Boolean,
  name: String,
  id: String,
  totalFile: String,
  totalFolder: String,
  click:Function,
  opened:Boolean,
  checkBoxClick:Function,
  selected:Boolean,
  editModal:Function
});

const openUrl = () => {
  router.push(`${props.id}`);
};

const handleClick = ()=>{
  props.click(props.id)
}

const handleCheckBoxClick=(event)=>{
  event.stopPropagation()
  props.checkBoxClick(false,props.id,event.target.checked)
}

const handleEditClick=(event)=>{
  event.stopPropagation()
  props.click(props.id)
  props.editModal("editModal")
}

</script>

<template>
  <div :class="['folder-card', { 'opened': opened, 'selected': selected }]" @click="handleClick">
    <div>
      <input type="checkbox" v-if="deletePermission" name="check" id="" v-on:click="handleCheckBoxClick($event)" />
    </div>
    <img :src="FolderIcon" class="icon" />
    <div class="d-flex flex-column">
      <p class="folder-name" :title="name">
        {{ name.length > 5 ? name.substring(0,5) + "..." : name }}
      </p>
      <p class="folder-stats">
        {{ totalFile }} Assets
      </p>
    </div>
    <v-icon @click="handleEditClick" v-if="editPermission" style="padding-left: 2rem;" class="icon" name="ri-edit-box-line" scale="0.8"/>
  </div>
</template>
<style scoped>
.folder-card:hover {
  cursor: pointer;
}
.flex-column {
  min-width: 37%;
  margin-right: -2rem;
}
.folder-card {
  color: #606060;
  padding: 0.6rem;
  display: flex;
  justify-content: space-evenly;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3rem;
  align-items: center;
  max-width: 100%;
  margin-right: 1.3rem;
  
  -webkit-box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.2);
}
input[type="checkbox"] {
  width: 17px;
  height: 17px;
  border-radius: 100px;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
}
p {
  margin: 0%;
}
.folder-name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.1rem;
}
.folder-stats {
  font-size: 12px;
  color: #606060;
}
.icon {
  width: 22.5px;
  height: 18px;
}
a{
  text-decoration: none;
}

.selected{
  background-color: rgba(0, 119, 255, 0.233) !important;
}

.opened{
  border-top: 2px solid #0146f4;
}
</style>
