import api_request from "../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class ContentTypeBuilder{
    api_request = new api_request("/api/service");

    async createPage(name, apiId, description, contentType, createdBy, createdAt){
        try {
            magicJson.endpoint = "contentTypeBuilder";
            magicJson.executor = "createPage";
            magicJson.data = [{
                name:name,
                apiId:apiId,
                description:description,
                contentType: contentType,
                createdBy: createdBy,
                createdAt: createdAt,
                modifiedBy: createdBy,
                modifiedAt: createdAt
            }];
            const result = await this.api_request.sendRequest(magicJson);
            return result.data[0];
          } catch (error) {
            return error;
          }
    }

    async getPages() {
        try {
            magicJson.endpoint = "contentTypeBuilder";
            magicJson.executor = "getPages";
            magicJson.data = [];
            const result = await this.api_request.sendRequest(magicJson);
            return result.data[0];
        } catch (error) {
            return error;
        }
    }

    async getPages(){
        try {
            magicJson.endpoint = "contentTypeBuilder";
            magicJson.executor = "getPages"
            const result = await this.api_request.sendRequest(magicJson)
            return result.data[0]
        } catch (error) {
            return error;
        }
    }

}

export default ContentTypeBuilder