<script setup>
import { ref, defineExpose, watch } from 'vue';

// Define props
const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
        default: "Name",
    },
    type: {
        type: String,
        default: "text",
    },
    value: {
        type: String,
        default: "",
    },
    width: {
        type: String,
    },
    placeholder: {
        type: String,
        default: "",
    },
    inputType: {
        type: String,
        default: "",
    },
});

const isChecked = ref(false);
const numberValue = ref();

watch(isChecked, (newValue) => {
    if (newValue) {
        // Checkbox checked, do something if needed
    }
});

const handleNumberInput = (e) => {
    numberValue.value = e.target.value;
}

// Reactive value based on prop
const value = ref(props.value);

// Method to update the reactive value
const updateValue = (event) => {
    value.value = event.target.value;
};

// Expose getData method
defineExpose({
    getData: () => {
        return { value: value.value };
    }
});


</script>

<template>
    <div class="formComponentBody">
        <div class="rangeBody">
            <div class="headingAndCheckbox">
                <input type="checkbox" v-model="isChecked" class="checkbox item"/>
                <div class="formComponent_Title item">{{ props.title }}</div>
            </div>
            <input :type="props.type" v-model="numberValue" :disabled="!isChecked"/>
        </div>
    </div>
</template>


<style scoped>
.item{
    display: inline-block;
    margin: 10px;
}

.checkbox{
    width: auto;
    padding: 0;
    margin: 0;
}

.formComponentBody .descriptionText {
    font-size: 0.9rem;
    font-weight: 400;
    color: #606060;
}

.formComponent_Title{
    padding-left: 10px;
}

.rangeBody{
    padding: 10px;
    padding-top: 0;
}
</style>